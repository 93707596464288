import React from 'react'
import s from './Slide.module.css';
import Svg from './SVG';

export default function Slide({slide}) {
    
  return (
    <div className={s.container} >
        <div className={s.image} style={{backgroundImage: `url(${slide.imagen})`}}/>
        <Svg className={s.svg} fill={slide.color}/>
        <div className={s.content}>
            <h3 className={s.titulo}>{slide.titulo}</h3>
            <p className={s.descripcion}>{slide.descripcion}</p>
            {
                slide.precio &&
                <p className={s.precio}>${slide.precio}</p>
            }

        </div>
    </div>
  )
}
