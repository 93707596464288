import * as React from "react"

const SvgComponent = (props) => {

  return <svg
    id="svgSwiper"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 2355 1880"
    height={"100%"}
    {...props}
  >
    <path d="M0 940v940h1177.6c941.7 0 1177.4-.3 1176.8-1.3-.5-.6-63.6-90.5-140.3-199.7-76.7-109.2-142.9-203.9-147.1-210.5-12.4-19.4-30.8-50.7-60.7-103-24.6-43.1-38.4-69.2-113.8-216.4-47.2-92.1-92-180.8-99.6-197-42.9-92.6-131.5-308.7-172.4-420.6-9.3-25.5-45.9-132.7-55.8-163.5-22.3-69.1-54.6-183.4-67.1-237.2C1485 77 1474.9 23.5 1473.7 4.5l-.2-4L736.8.2 0 0v940z" />
  </svg>
}

export default SvgComponent
