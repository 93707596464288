import React, { useEffect, useState } from 'react';
import s from './Footer.module.css';
import logoiso from '../../assets/logoisoblanco.png';
import { BsInstagram } from 'react-icons/bs';
import { BsWhatsapp } from 'react-icons/bs';
import { TbMap2 } from 'react-icons/tb';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

export default function Footer() {

    const [horarios, setHorarios] = useState({semanaDe: "", semanaHasta: "", finesDe: "", finesHasta: ""})

    useEffect(()=>{
        firebase.firestore()
        .collection('horarios')
        .doc("1")
        .get()
        .catch(e=>{
            console.log(e)
        })
        .then((snapshot)=>{
            setHorarios(snapshot.data());
        });
    },[])
    
    return (
        <div className={s.container}>
            <div className={s.direccionContainer}>
                <img src={logoiso} className={s.logoiso} alt="Logo Suki"/>
                <div className={s.divider}/>
                <p className={s.direccion}>Av. del Libertador 17.610</p>
                <p className={s.direccion}>Beccar, Bs. As.</p>
            </div>
            <div className={s.contactoContainer}>
                <div className={s.iconContainer}>
                    <BsInstagram size='1.8rem' color='white' onClick={()=>{window.open("https://www.instagram.com/suki.sushi.ba",'_blank')}}/>
                </div>
                <div className={s.iconContainer}> 
                    <BsWhatsapp size='1.8rem' color='white' onClick={()=>{window.open("https://api.whatsapp.com/send?phone=+5491127368938&text=Buen Dia! Quisiera hacerles un pedido.",'_blank')}}/>
                </div>
                <div className={s.iconContainer} onClick={()=>{window.open("https://www.google.com/maps/place/Suki+Sushi/@-34.4577649,-58.5221894,17.39z/data=!4m5!3m4!1s0x95bcaf745f43950f:0xbfd40e275de86193!8m2!3d-34.457218!4d-58.5220974",'_blank')}}>
                    <TbMap2 size='1.8rem' color='white'/>
                </div>
            </div>
            <div className={s.horariosContainer}>
                <p className={s.horariosTitle}>Horarios</p>
                <div className={s.divider}/>
                <p className={s.horarios}>Horario de verano</p>
                <p className={s.horarios}>Martes a Domingo</p>
                <p className={s.horarios}>{horarios.semanaDe} a {horarios.semanaHasta} Hs.</p>
                {/* <p className={s.horarios}>Sabados y Domingos</p>
                <p className={s.horarios}>{horarios.finesDe} a {horarios.finesHasta} Hs.</p> */}
            </div>
        </div>
    )
}
