import React, { useEffect, useState } from 'react'
import s from './Navbar.module.css';
import logoiso from '../../assets/logoiso.png';
import { useMediaQuery } from 'react-responsive'
import { IoIosMenu } from 'react-icons/io';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

export default function Navbar({ setModalContacto, setModalNavbar, menu}) {

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' })
    
    if(!isTabletOrMobile) return (
        <div className={s.container}>
            <a href='#home' className={s.btn}>HOME</a>
            <p className={s.btn} onClick={()=>{window.open(menu,'_blank')}}>MENÚ</p>
            <img className={s.logoiso} src={logoiso} alt='logo'/>
            <p className={s.btn} onClick={()=>{window.scrollTo(0, document.getElementById("Promos").offsetTop-100);}}>PROMOS</p>
            <p className={s.btn} onClick={()=>{setModalContacto(true);}}>CONTACTO</p>
        </div>
    )

    if(isTabletOrMobile) return(
        <div className={s.container}>
            <IoIosMenu onClick={()=>{setModalNavbar(true);}} className={s.icon} size='3.5rem' color='black'/>
            <img className={s.logoiso} src={logoiso} alt='logo'/>
        </div>
    )

}
