import React, { useRef, useLayoutEffect, useState, useEffect } from 'react';
import s from './Promos.module.css';
import barras from '../../assets/barrasChicas.png';
import Swiper from '../Swiper/Swiper';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

const slidesHard = [{
        titulo: "Proba nuestro nuevo pollo al horno con papas",
        descripcion: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
        imagen: "https://images.pexels.com/photos/248444/pexels-photo-248444.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        precio: 22243,
        color: "#ff8629"
    },{
        titulo: "El mejor sushi de Buenos Aires ahora con delivery",
        descripcion: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
        imagen: "",
        precio: 23423,
        color: "#3b83f7"
    },{
        titulo: "Nigiris en descuento y promos nuevas!",
        descripcion: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
        imagen: "",
        precio: 5647,
        color: "#f075ee"
    },{
        titulo: "El pibe que atiende te habla de artic monkeys, ignorenlo pls",
        descripcion: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
        imagen: "",
        precio: 8544,
        color: "#53eda8"
    }
];

export default function Promos() {
    const ref = useRef(null);

    const [height, setHeight] = useState(0);
    const [slides, setSlides] = useState([]);
  
    useLayoutEffect(() => {
      setHeight(ref.current.offsetHeight);
    }, []);

    useEffect(()=>{
        firebase.firestore()
        .collection('slides')
        .get()
        .catch(e=>{
            console.log(e)
        })
        .then((snapshot)=>{
            let data = [];
            snapshot.forEach(doc => {
                data.push({...doc.data(), id: doc.id});
            });
            data.sort((a,b)=>a.indice-b.indice);
            setSlides(data);
        })
    },[])

  return (
    <div id='Promos'>
        <img className={s.barras1} style={{height: ref.current ? `${height}px` : null}} src={barras} alt='barras'/>
        <img className={s.barras2} style={{height: ref.current ? `${height}px` : null}} src={barras} alt='barras'/>
        
        <div className={s.container} ref={ref}>
            <p className={s.promosTitle}>Tentate con nuestras promociones</p>
            <Swiper slides={slides}/>
            <iframe title='Maps' src={`https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6579.609459858292!2d-58.5261152310206!3d-34.45708716863528!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcaf745f43950f%3A0xbfd40e275de86193!2sSuki%20Sushi!5e0!3m2!1ses!2sus!4v1671722125831!5m2!1ses!2sus`} className={s.iframe} allowFullScreen></iframe>
        </div>
    </div>
  )
}
