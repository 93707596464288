import React, { useRef } from "react";
import s from './Swiper.module.css';
import Slide from "../Slide/Slide";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import arrow from '../../assets/arrow.png';

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";

export default function SwiperMain({slides}) {
    const swiperRef = useRef();
  return (
    <div className={s.container}>
        <div onClick={() => swiperRef.current?.slidePrev()} className={s.btnPrev}>
            <img src={arrow} alt='swiper next' className={s.prevIcon}/>
        </div>
      <Swiper
        spaceBetween={0}
        centeredSlides={true}
        speed={1500}
        autoplay={{
          delay: 5500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true
        }}
        navigation={false}
        modules={[Autoplay, Pagination, Navigation]}
        className={s.swiper}
        onBeforeInit={(swiper) => {
            swiperRef.current = swiper;
        }}
      >
        {
            slides?.map((s,i)=>{
                return <SwiperSlide key={i} className={s.swiperSlide}><Slide slide={s}/></SwiperSlide>
            })
        }
      </Swiper>
      <div onClick={() => swiperRef.current?.slideNext()} className={s.btnNext}>
        <img src={arrow} alt='swiper next' className={s.nextIcon}/>
      </div>
    </div>
  );
}
