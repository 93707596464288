import React from 'react';
import s from './Hero.module.css';
import ondas from '../../assets/ondas.png';
import barras from '../../assets/barras.png';
import flor from '../../assets/flor.png';
import florAzul from '../../assets/florAzul.png';
import nube from '../../assets/nube.png';
import isotipo from '../../assets/isotipo.png';
import instagram from '../../assets/igNaranja.png';
import wpp from '../../assets/wppNaranja.png';
import { CSSTransition } from 'react-transition-group';

export default function Hero() {

  return (
    <div className={s.wrapper}>
        <div className={s.container} id="home">
            <div className={s.sol}/>
            <CSSTransition
                in={true}
                timeout={0}
                appear={true}
                key={0}
                classNames={{ appear: s.MyClassEnterActive, enterDone: s.MyClassEnterDone }}
                >
                <img className={s.ondas} src={ondas} alt='ondas'/>
            </CSSTransition>
            <div className={s.circuloAzul}/>
            <div className={s.circuloAzulEje}/>
            <CSSTransition
                in={true}
                timeout={0}
                appear={true}
                key={0}
                classNames={{ appear: s.MyClassEnterActive, enterDone: s.MyClassEnterDone }}
                >
                <img className={s.barras} src={barras} alt='barras'/>
            </CSSTransition>

            <CSSTransition
                in={true}
                timeout={0}
                appear={true}
                key={0}
                classNames={{ appear: s.MyClassEnterActive, enterDone: s.MyClassEnterDone }}
                >
                <div className={s.circuloDorado}/>
            </CSSTransition>
            
            <img className={s.flor} src={flor} alt='flor'/>
            <img className={s.florAzul} src={florAzul} alt='flor'/>
            <img className={s.nube1} src={nube} alt='nube'/>
            <img className={s.nube2} src={nube} alt='nube'/>
            <img className={s.nube3} src={nube} alt='nube'/>
            <img className={s.nube4} src={nube} alt='nube'/>
            <div className={s.header}>
                <img className={s.isotipo} src={isotipo} alt='isotipo suki'/>
                <p>Av. del Libertador 17610</p>
                <p>Beccar, Bs. As.</p>
                <div className={s.headerSection}>
                    <img className={s.headerIcon} src={wpp} alt='logo wpp'/>
                    <p>11 2736 8938</p>
                </div>
                <div className={s.headerSection}>
                    <img className={s.headerIcon} src={instagram} alt='instagram wpp'/>
                    <p>suki.sushi.ba</p>
                </div>
                <p>suki.com.ar</p>
            </div>
        </div>
        
        <div className={s.leaves}>
            <i><img src={flor} className={s.hoja} alt="particula"/></i>
            <i><img src={flor} className={s.hoja} alt="particula"/></i>
            <i><img src={flor} className={s.hoja} alt="particula"/></i>
            <i><img src={flor} className={s.hoja} alt="particula"/></i>
            <i><img src={flor} className={s.hoja} alt="particula"/></i>
            <i><img src={flor} className={s.hoja} alt="particula"/></i>
            <i><img src={flor} className={s.hoja} alt="particula"/></i>
            <i><img src={flor} className={s.hoja} alt="particula"/></i>
            <i><img src={flor} className={s.hoja} alt="particula"/></i>
            <i><img src={flor} className={s.hoja} alt="particula"/></i>
            <i><img src={flor} className={s.hoja} alt="particula"/></i>
            <i><img src={flor} className={s.hoja} alt="particula"/></i>
            <i><img src={flor} className={s.hoja} alt="particula"/></i>
            <i><img src={flor} className={s.hoja} alt="particula"/></i>
            <i><img src={flor} className={s.hoja} alt="particula"/></i>
            <i><img src={flor} className={s.hoja} alt="particula"/></i>
            <i><img src={flor} className={s.hoja} alt="particula"/></i>
            <i><img src={flor} className={s.hoja} alt="particula"/></i>
            <i><img src={flor} style={{marginTop: "60px"}}  className={s.hoja} alt="particula"/></i>
            <i><img src={flor} className={s.hoja} alt="particula"/></i>
            <i><img src={flor} className={s.hoja} alt="particula"/></i>
            <i><img src={flor} className={s.hoja} alt="particula"/></i>
            <i><img src={flor} style={{marginTop: "60px"}} className={s.hoja} alt="particula"/></i>
            <i><img src={flor} className={s.hoja} alt="particula"/></i>
            <i><img src={flor} className={s.hoja} alt="particula"/></i>
            <i><img src={flor} className={s.hoja} alt="particula"/></i>
            
        </div>
    </div>
  )
}
