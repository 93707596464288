import './App.css';
import React, {useState, useEffect} from 'react';
import Navbar from './components/Navbar/Navbar';
import Hero from './components/Hero/Hero';
import Promos from './components/Promos/Promos';
import Footer from './components/Footer/Footer';
import Modal from 'react-modal';
import { IoIosClose } from 'react-icons/io';
import isotipo from '../src/assets/isotipo.png';
import { BsInstagram } from 'react-icons/bs';
import { BsWhatsapp } from 'react-icons/bs';
import { TbMap2 } from 'react-icons/tb';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import Admin from './components/Admin/Admin';
import { Toaster } from 'react-hot-toast';

const firebaseConfig = {
    apiKey: "AIzaSyAXEolBC8g9unhsJYBoSKzaM1ynStHUUK4",
    authDomain: "suki-bd17b.firebaseapp.com",
    projectId: "suki-bd17b",
    storageBucket: "suki-bd17b.appspot.com",
    messagingSenderId: "204152580778",
    appId: "1:204152580778:web:5e2e508249bd009f7fbed3",
    measurementId: "G-TWEF1VJ32G"
};

let app;

if (firebase.apps.length === 0) {
  firebase.initializeApp(firebaseConfig)
}

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      background: "#152F48",
      border: "none",
      borderRadius: "10px",
      padding: 0,
      overflow: "visible"
    },
    overlay: {
        zIndex: 1000,
        background: "#00000060",
    }
};

const navBarStyles = {
    content: {
        left: "0%",
        top: "25vh",
        width: "60%",
        height: window.innerHeight *2,
        background: "#152F48",
        border: "none",
        padding: 0,
        paddingLeft: "0px",
        paddingRight: "10px",
        overflow: 'hidden'
    },
    overlay: {
        zIndex: 2000,
        background: "#00000060",
    }
};

function App() {

    const [modalPromos, setModalPromos] = useState(false);
    const [modalContacto, setModalContacto] = useState(false);
    const [modalNavbar, setModalNavbar] = useState(false);
    const [menu, setMenu] = useState("");

    useEffect(()=>{
        firebase.firestore()
        .collection('menu')
        .doc("1")
        .get()
        .catch(e=>{
            console.log(e)
        })
        .then((snapshot)=>{
            setMenu(snapshot.data().menu);
        })
    },[])

  return (

    <div className="App">
        <Toaster/>
        
        <Navbar setModalPromos={setModalPromos} setModalContacto={setModalContacto} setModalNavbar={setModalNavbar} menu={menu}/>
        <Hero/>
        <Promos/>
        <Footer/>
        {/* <Admin/> */}

        <Modal
            isOpen={modalPromos}
            onRequestClose={()=>{setModalPromos(false)}}
            style={customStyles}
            closeTimeoutMS={0}
        >    
            <div className='closeContainer'>
                <IoIosClose onClick={()=>{setModalPromos(false);}} className="promosClose" size='4rem' color='white'/>
            </div>
            <div className='promosContainer'>
                <p>Proximamente...</p>
            </div>
        </Modal>

        <Modal
            isOpen={modalContacto}
            onRequestClose={()=>{setModalContacto(false)}}
            style={customStyles}
            closeTimeoutMS={0}
        >
            <div className='closeContainer'>
                <IoIosClose onClick={()=>{setModalContacto(false);}} className="contactoClose" size='4rem' color='white'/>
            </div>
            <div className='contactoContainer'>
                <img src={isotipo} className="logoiso" alt="Logo Suki"/>
                <p>@suki.shushi.ba</p>
                <p>11 2736 8938</p>
                <p>Av. del Libertador 17610, Beccar</p>
                <div className="redesContainer">
                    <div className="iconContainer">
                        <BsInstagram size='1.8rem' color='white' onClick={()=>{window.open("https://www.instagram.com/suki.sushi.ba",'_blank')}}/>
                    </div>
                    <div className="iconContainer"> 
                        <BsWhatsapp size='1.8rem' color='white' onClick={()=>{window.open("https://api.whatsapp.com/send?phone=+5491127368938&text=Buen Dia! Quisiera hacerles un pedido.",'_blank')}}/>
                    </div>
                    <div className="iconContainer" onClick={()=>{window.open("https://www.google.com/maps/place/Suki+Sushi/@-34.4577649,-58.5221894,17.39z/data=!4m5!3m4!1s0x95bcaf745f43950f:0xbfd40e275de86193!8m2!3d-34.457218!4d-58.5220974")}}>
                        <TbMap2 size='1.8rem' color='white'/>
                    </div>
                </div>
            </div>
        </Modal>

        <Modal
            isOpen={modalNavbar}
            onRequestClose={()=>{setModalNavbar(false)}}
            style={navBarStyles}
            closeTimeoutMS={0}
            
        >
            <div className='navbar'>
                <div className='header'>
                    <IoIosClose onClick={()=>{setModalNavbar(false);}} className="closeIcon" size='4rem' color='white'/>
                    <img className="logoiso" src={isotipo} alt='logo'/>
                </div>
                <div className='navbarBtns'>
                    <a href='#home' onClick={()=>{setModalNavbar(false);}}>
                        <div className='btnContainer'>
                            <p className='btn'>HOME</p>
                        </div>
                    </a> 
                    <div className='btnContainer' onClick={()=>{window.open(menu,'_blank')}}>
                        <p className='btn'>MENU</p>
                    </div>
                    <div className='btnContainer' onClick={()=>{setModalNavbar(false);  window.scrollTo(0, document.getElementById("Promos").offsetTop-100);}}>
                        <p className='btn'>PROMOS</p>
                    </div>
                    <div className='btnContainer' onClick={()=>{setModalNavbar(false); setModalContacto(true)}}>
                        <p className='btn'>CONTACTO</p>
                    </div>
                </div>
            </div>
        </Modal>
    </div>
  );
}

export default App;
